import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  

  constructor(private http: HttpClient,private router:Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {

    return this.currentUserSubject.value;
    
  }

  login(url:any,pbody:any) {
    // url = environment?.baseUrl + url;
    return this.http
      .post<any>(url, pbody)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }



  getGuestAuthApiData(url: string): Observable<any> {
    
    url = environment?.baseUrl + url;
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return this.http.get<any>(url, options).pipe(
      tap((_) => console.log('test')),
      catchError(this.handleError('err', []))
    );
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (err: any): Observable<T> => {
      
      if (err.status == 500) {
       
        return err.error.message;
      }
      if (err?.error?.statusCode == 401) {
        this.router.navigate(['/login']);
      } else {
        return err;
      }
      return of(result as T);
    };
  }
  guestAuthGetapi(url: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getGuestAuthApiData(url).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  guestpost(url: string, pbody: any): Observable<any> {
    url = environment?.baseUrl + url;
    return this.http.post(url, pbody).pipe(map((res) => res));
  }

  postGuestAuthApiData(url: string, body: any): Observable<any> {
   
    url = environment?.baseUrl + url;
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return this.http.post<any>(url, body, options).pipe(
      tap((_) => console.log('test')),
      catchError(this.handleError('err', []))
    );
  }

  putGuestAuthApiData(url: string, body: any): Observable<any> {
    
    url = environment?.baseUrl + url;
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return this.http.put<any>(url, body, options).pipe(
      tap((_) => console.log('test')),
      catchError(this.handleError('err', []))
    );
  }

  deleteGuestAuthApiData(url: string): Observable<any> {
    
    url = environment?.baseUrl + url;
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return this.http.delete<any>(url, options).pipe(
      tap((_) => console.log('test')),
      catchError(this.handleError('err', []))
    );
  }
}
