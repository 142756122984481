import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
 
    path: '',
    title: '',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  // Admin Modules

  {
    path: '/admin',
    title: '',
    iconType: 'material-icons-two-tone',
    icon: 'space_dashboard',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [''],
    submenu: [
      {
        path: '/admin/dashboard/main',
        title: 'MENUITEMS.DASHBOARD.LIST.DASHBOARD1',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    
      // {
      //   path: '/admin/dashboard/dashboard2',
      //   title: 'MENUITEMS.DASHBOARD.LIST.DASHBOARD2',
      //   iconType: '',
      //   icon: '',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: [''],
      //   submenu: [],
      // },
      // {
      //   path: '/admin/dashboard/teacher-dashboard',
      //   title: 'MENUITEMS.DASHBOARD.LIST.TEACHER-DASHBOARD',
      //   iconType: 'material-icons-two-tone',
      //   icon: '',
      //   class: '',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: [''],
      //   submenu: [],
      // },
      // {
      //   path: '/admin/dashboard/student-dashboard',
      //   title: 'MENUITEMS.DASHBOARD.LIST.STUDENT-DASHBOARD',
      //   iconType: 'material-icons-two-tone',
      //   icon: '',
      //   class: '',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: [''],
      //   submenu: [],
      // },
    ],
  },



  {
    path: '/admin/dashboard/main',
    title: 'Dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'dashboard',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },
  {
    path: '/admin/dashboard/ecommerce-banner',
    title: 'Banner',
    iconType: 'material-icons-two-tone',
    icon: 'contacts',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },
  // {
  //   path: '',
  //   title: 'Ecommerce Banner',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'person',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [],
  // },






  {
    path: '',
    title: 'Users',
    iconType: 'material-icons-two-tone',
    icon: 'person_outline',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [
      {
        path: 'admin/dashboard/user/customer',
        title: 'Customer Details',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
      {
        path: 'admin/dashboard/user/partner',
        title: 'Partner Details',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },

    ],
  },


  {
    path: '',
    title: 'Master',
    iconType: 'material-icons-two-tone',
    icon: 'healing',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [
      {
        path: '/admin/dashboard/master/category',
        title: 'Category',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/dashboard/master/subcategory',
        title: 'Sub Category',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      // {
      //   path: '/admin/courses/edit-course',
      //   title: 'MENUITEMS.COURSES.LIST.EDIT-COURSE',
      //   iconType: '',
      //   icon: '',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: [''],
      //   submenu: [],
      // },
      // {
      //   path: '/admin/courses/about-course',
      //   title: 'MENUITEMS.COURSES.LIST.ABOUT-COURSE',
      //   iconType: '',
      //   icon: '',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: [''],
      //   submenu: [],
      // },
    ],
  },
  // {
  //   path: '',
  //   title: 'MENUITEMS.LIBRARY.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'local_library',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/library/all-assets',
  //       title: 'MENUITEMS.LIBRARY.LIST.ALL-LIBRARY',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/library/add-asset',
  //       title: 'MENUITEMS.LIBRARY.LIST.ADD-LIBRARY',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/library/edit-asset',
  //       title: 'MENUITEMS.LIBRARY.LIST.EDIT-LIBRARY',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.DEPARTMENTS.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'business',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/departments/all-departments',
  //       title: 'MENUITEMS.DEPARTMENTS.LIST.ALL-DEPARTMENT',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/departments/add-department',
  //       title: 'MENUITEMS.DEPARTMENTS.LIST.ADD-DEPARTMENT',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/departments/edit-department',
  //       title: 'MENUITEMS.DEPARTMENTS.LIST.EDIT-DEPARTMENT',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: '',
    title: 'Products',
    iconType: 'material-icons-two-tone',
    icon:' person_pin',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [
      {
        path: '/admin/dashboard/products/addcategory',
        title: 'Add Category',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/dashboard/products/addsubcategory',
        title: 'Add Sub Category',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/dashboard/products/addproduct',
        title: 'Add Product',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/dashboard/products/productlist',
        title: 'Product List',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  },
  // {
  //   path: '',
  //   title: 'MENUITEMS.HOLIDAY.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'airline_seat_individual_suite',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/holidays/all-holidays',
  //       title: 'MENUITEMS.HOLIDAY.LIST.ALL-HOLIDAY',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/holidays/add-holiday',
  //       title: 'MENUITEMS.HOLIDAY.LIST.ADD-HOLIDAY',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/holidays/edit-holiday',
  //       title: 'MENUITEMS.HOLIDAY.LIST.EDIT-HOLIDAY',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.FEES.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'monetization_on',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/fees/all-fees',
  //       title: 'MENUITEMS.FEES.LIST.ALL-FEES',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/fees/add-fees',
  //       title: 'MENUITEMS.FEES.LIST.ADD-FEES',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/fees/edit-fees',
  //       title: 'MENUITEMS.FEES.LIST.EDIT-FEES',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/fees/fee-receipt',
  //       title: 'MENUITEMS.FEES.LIST.FEE-RECEIPT',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.ATTENDANCE.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'history_edu',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/attendance/staff',
  //       title: 'MENUITEMS.ATTENDANCE.LIST.STAFF',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['Admin'],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/attendance/details',
  //       title: 'MENUITEMS.ATTENDANCE.LIST.DETAILS',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['Admin'],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/attendance/attendance-sheet',
  //       title: 'MENUITEMS.ATTENDANCE.LIST.SHEET',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['Admin'],
  //       submenu: [],
  //     },
  //   ],
  // },

  // Teacher Modules

  // {
  //   path: '/teacher/dashboard',
  //   title: 'MENUITEMS.TEACHER.LIST.DASHBOARD',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'space_dashboard',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Teacher'],
  //   submenu: [],
  // },
  // {
  //   path: '/teacher/lectures',
  //   title: 'MENUITEMS.TEACHER.LIST.LECTURES',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'menu_book',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Teacher'],
  //   submenu: [],
  // },
  // {
  //   path: '/teacher/leave-request',
  //   title: 'MENUITEMS.TEACHER.LIST.LEAVE-REQUEST',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'offline_pin',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Teacher'],
  //   submenu: [],
  // },
  // {
  //   path: '/teacher/exam-schedule',
  //   title: 'MENUITEMS.TEACHER.LIST.EXAM-SCHEDULE',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'history_edu',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Teacher'],
  //   submenu: [],
  // },
  // {
  //   path: '/teacher/settings',
  //   title: 'MENUITEMS.TEACHER.LIST.SETTINGS',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'settings',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Teacher'],
  //   submenu: [],
  // },

  // Student Modules

  // {
  //   path: '/student/dashboard',
  //   title: 'MENUITEMS.STUDENT.LIST.DASHBOARD',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'space_dashboard',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Student'],
  //   submenu: [],
  // },
  // {
  //   path: '/student/homework',
  //   title: 'MENUITEMS.STUDENT.LIST.HOMEWORK',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'article',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Student'],
  //   submenu: [],
  // },
  // {
  //   path: '/student/leave-request',
  //   title: 'MENUITEMS.STUDENT.LIST.LEAVE-REQUEST',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'offline_pin',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Student'],
  //   submenu: [],
  // },
  // {
  //   path: '/student/timetable',
  //   title: 'MENUITEMS.STUDENT.LIST.TIME-TABLE',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'table_chart',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Student'],
  //   submenu: [],
  // },
  // {
  //   path: '/student/settings',
  //   title: 'MENUITEMS.STUDENT.LIST.SETTINGS',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'settings',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Student'],
  //   submenu: [],
  // },
  // Common Module

  // {
  //   path: '',
  //   title: 'MENUITEMS.APPS.TEXT',
  //   iconType: '',
  //   icon: '',
  //   class: '',
  //   groupTitle: true,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['All'],
  //   submenu: [],
  // },
  // {
  //   path: 'calendar',
  //   title: 'MENUITEMS.CALENDAR.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'event_note',
  //   class: '',
  //   groupTitle: false,
  //   badge: 'New',
  //   badgeClass: 'badge bg-blue sidebar-badge float-end',
  //   role: ['All'],
  //   submenu: [],
  // },
  {
    path: '/admin/dashboard/orders',
    title: 'Orders',
    iconType: 'material-icons-two-tone',	
    icon: 'layers',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },
  {
    path: '/admin/dashboard/generate-report',
    title: 'Generate Report',
    iconType: 'material-icons-two-tone',
    icon: 'contacts',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },
  // {
  //   path: '',
  //   title: 'Email',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'email',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['All'],
  //   submenu: [
  //     {
  //       path: '/email/inbox',
  //       title: 'Inbox',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/email/compose',
  //       title: 'Compose',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/email/read-mail',
  //       title: 'Read Email',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'More Apps',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'stars',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '5',
  //   badgeClass: 'badge bg-orange sidebar-badge float-end',
  //   role: ['All'],
  //   submenu: [
  //     {
  //       path: '/apps/chat',
  //       title: 'Chat',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/apps/dragdrop',
  //       title: 'Drag & Drop',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/apps/contact-grid',
  //       title: 'Contact Grid',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/apps/support',
  //       title: 'Support',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Widgets',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'widgets',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/widget/chart-widget',
  //       title: 'Chart Widget',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/widget/data-widget',
  //       title: 'Data Widget',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Components',
  //   iconType: '',
  //   icon: '',
  //   class: '',
  //   groupTitle: true,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [],
  // },
  // {
  //   path: '',
  //   title: 'User Interface (UI)',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'dvr',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/ui/alerts',
  //       title: 'Alerts',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/badges',
  //       title: 'Badges',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/chips',
  //       title: 'Chips',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/modal',
  //       title: 'Modal',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/buttons',
  //       title: 'Buttons',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/expansion-panel',
  //       title: 'Expansion Panel',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/bottom-sheet',
  //       title: 'Bottom Sheet',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/dialogs',
  //       title: 'Dialogs',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/cards',
  //       title: 'Cards',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/labels',
  //       title: 'Labels',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/list-group',
  //       title: 'List Group',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/snackbar',
  //       title: 'Snackbar',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/preloaders',
  //       title: 'Preloaders',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/progressbars',
  //       title: 'Progress Bars',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/tabs',
  //       title: 'Tabs',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/typography',
  //       title: 'Typography',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/helper-classes',
  //       title: 'Helper Classes',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Forms',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'subtitles',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/forms/form-controls',
  //       title: 'Form Controls',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/forms/advance-controls',
  //       title: 'Advanced Controls',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/forms/form-example',
  //       title: 'Form Examples',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/forms/form-validation',
  //       title: 'Form Validation',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/forms/wizard',
  //       title: 'Form Wizard',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/forms/editors',
  //       title: 'Editors',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Tables',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'view_list',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/tables/basic-tables',
  //       title: 'Basic Tables',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/tables/material-tables',
  //       title: 'Material Tables',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/tables/ngx-datatable',
  //       title: 'ngx-datatable',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Medias',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'image_search',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/media/gallery',
  //       title: 'Image Gallery',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Charts',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'insert_chart',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '7',
  //   badgeClass: 'badge bg-green sidebar-badge float-end',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/charts/echart',
  //       title: 'Echart',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/charts/apex',
  //       title: 'Apex',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/charts/chartjs',
  //       title: 'ChartJS',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/charts/ngx-charts',
  //       title: 'Ngx-Charts',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/charts/gauge',
  //       title: 'Gauge',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Timeline',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'timeline',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/timeline/timeline1',
  //       title: 'Timeline 1',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/timeline/timeline2',
  //       title: 'Timeline 2',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Icons',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'eco',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/icons/material',
  //       title: 'Material Icons',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/icons/font-awesome',
  //       title: 'Font Awesome',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Authentication',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'supervised_user_circle',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/authentication/signin',
  //       title: 'Sign In',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/authentication/signup',
  //       title: 'Sign Up',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/authentication/forgot-password',
  //       title: 'Forgot Password',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/authentication/locked',
  //       title: 'Locked',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/authentication/page404',
  //       title: '404 - Not Found',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/authentication/page500',
  //       title: '500 - Server Error',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Extra Pages',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'description',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/extra-pages/profile',
  //       title: 'Profile',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/extra-pages/pricing',
  //       title: 'Pricing',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/extra-pages/invoice',
  //       title: 'Invoice',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/extra-pages/faqs',
  //       title: 'Faqs',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/extra-pages/blank',
  //       title: 'Blank Page',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Maps',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'map',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/maps/google',
  //       title: 'Google Map',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Multi level Menu',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'slideshow',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/multilevel/first1',
  //       title: 'First',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/',
  //       title: 'Second',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-sub-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [
  //         {
  //           path: '/multilevel/secondlevel/second1',
  //           title: 'Second 1',
  //           iconType: '',
  //           icon: '',
  //           class: 'ml-menu2',
  //           groupTitle: false,
  //           badge: '',
  //           badgeClass: '',
  //           role: [''],
  //           submenu: [],
  //         },
  //         {
  //           path: '/',
  //           title: 'Second 2',
  //           iconType: '',
  //           icon: '',
  //           class: 'ml-sub-menu2',
  //           groupTitle: false,
  //           badge: '',
  //           badgeClass: '',
  //           role: [''],
  //           submenu: [
  //             {
  //               path: '/multilevel/thirdlevel/third1',
  //               title: 'third 1',
  //               iconType: '',
  //               icon: '',
  //               class: 'ml-menu3',
  //               groupTitle: false,
  //               badge: '',
  //               badgeClass: '',
  //               role: [''],
  //               submenu: [],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       path: '/multilevel/first3',
  //       title: 'Third',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
];
